import React from 'react';
import './App.css';
import ReactGA from 'react-ga';

import Footer from './components/Footer'
import Header from './components/Header'
import LandingPage from './components/LandingPage'

function App() {
  ReactGA.pageview('/homepage');

  return (
    <div className="App">
      <Header />
      <LandingPage />
      <Footer />
    </div>
  );
}

export default App;
