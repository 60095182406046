import React from 'react';

import { Container, Navbar, Nav, NavItem, NavLink, Collapse } from 'reactstrap';

function IndexNavBar() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 399 ||
                document.body.scrollTop > 399
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 400 ||
                document.body.scrollTop < 400
            ) {
                setNavbarColor("navbar-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    return (
        <>
            <Navbar className={"fixed-top " + navbarColor} color="info" expand="xl">
                <Container>
                    <img
                        alt="..." width="64" height="64"
                        className=" img-fluid "
                        src={require("../assets/images/logo.png")}
                    ></img>
                    <Nav navbar>
                        <NavItem>
                            <NavLink href="#home">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#services">Services</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#about-us">About Us</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#contact-us">Contact Us</NavLink>
                        </NavItem>
                    </Nav>
                </Container>
            </Navbar>
        </>
    )
}

export default IndexNavBar