import React, { Component } from 'react'

import IndexNavBar from './IndexNavBar'

import { Container, Row, Col, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReactGA from 'react-ga';

class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstFocus: false,
            setFirstFocus: false,
            lastFocus: false,
            setLastFocus: false,
            name: "default",
            phone: "",
            email: "",
            message: "",
            token: ""
        };
    }

    async componentDidMount() {
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');
        this.setState({ token: token })
    }

    render() {
        return (
            <>
                <IndexNavBar />
                <div id="home">
                    <div id="services" className="section section-team text-center">
                        <Container>
                            <h2 className="title">Services</h2>
                            <div className="team">
                                <Row>
                                    <Col md="4">
                                        <img
                                            alt="..." width="50" height="50"
                                            className=" img-fluid "
                                            src={require("../assets/images/icon.png")}
                                        ></img>
                                        <div className="team-player">
                                            <h4 className="title">Software Development Outsourcing</h4>
                                            <p className="description">
                                                Augment your team with highly talented offshore software developers, mobile developers, quality engineers and software architects. We offer various type of engagements: extended team, fixed price & hybrid.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <img
                                            alt="..." width="50" height="50"
                                            className=" img-fluid "
                                            src={require("../assets/images/icon2.png")}
                                        ></img>
                                        <div className="team-player">
                                            <h4 className="title">Cloud Computing</h4>
                                            <p className="description">
                                                Our team can help you migrate your applications and platforms to cloud (Amazon Web Services, Azure Cloud, Google Cloud Platform). We work with you to design a cloud compatible architecture and complete migration plan. Our experts support you throughout the migration process.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <img
                                            alt="..." width="50" height="50"
                                            className=" img-fluid "
                                            src={require("../assets/images/icon3.png")}
                                        ></img>
                                        <div className="team-player">
                                            <h4 className="title">Mobile App Development</h4>
                                            <p className="description">
                                                You can outsource complete development of your mobile applications or augment your team with offshore development resources specializing on iOS and Android platforms. We do native as well as cross platform mobile development for a faster time to market.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div id="about-us" className="section section-about-us">
                        <div
                            className="section-image"
                            style={{
                                backgroundImage: "url(" + require("../assets/images/bg23.jpg") + ")"
                            }}
                        >
                        </div>
                        <div className="section section-about-us">
                            <Container>
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" md="8">
                                        <h2 className="title">About Us</h2>
                                        <h5 className="description">
                                            Our team consists of high performance programmers, architects and quality engineers. We hire the best from the market and empower them to create high quality software products for our customers. We will continue to work relentlessly to become the technological standard, providing big picture insights and solutions for companies of all sizes. Get in touch to learn more.
                                        </h5>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div id="contact-us" className="section section-contact-us text-center">
                        <Container>
                            <h2 className="title">Contact Us</h2>
                            <p className="description">Your project is very important to us.</p>
                            <p className="description">Fill out the form or reach us at +1 (416) 728-6743</p>
                            <Row>
                                <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                                    <InputGroup
                                        className={
                                            "input-lg" + (this.state.firstFocus ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="now-ui-icons users_circle-08"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Full Name..."
                                            type="text"
                                            onFocus={() => this.setState({ firstFocus: true })}
                                            onBlur={() => this.setState({ firstFocus: false })}
                                            onChange={e => this.setState({ name: e.target.value })}
                                        ></Input>
                                    </InputGroup>
                                    <InputGroup
                                        className={
                                            "input-lg" + (this.state.firstFocus ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="now-ui-icons tech_mobile"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Phone Number..."
                                            type="text"
                                            onFocus={() => this.setState({ firstFocus: true })}
                                            onBlur={() => this.setState({ firstFocus: false })}
                                            onChange={e => this.setState({ phone: e.target.value })}
                                        ></Input>
                                    </InputGroup>
                                    <InputGroup
                                        className={
                                            "input-lg" + (this.state.lastFocus ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="now-ui-icons ui-1_email-85"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email..."
                                            type="text"
                                            onFocus={() => this.setState({ lastFocus: true })}
                                            onBlur={() => this.setState({ lastFocus: true })}
                                            onChange={e => this.setState({ email: e.target.value })}
                                        ></Input>
                                    </InputGroup>
                                    <div className="textarea-container">
                                        <Input
                                            cols="80"
                                            name="name"
                                            placeholder="Type a message..."
                                            rows="4"
                                            type="textarea"
                                            onChange={e => this.setState({ message: e.target.value })}
                                        ></Input>
                                    </div>
                                    <div className="send-button">
                                        <Button
                                            block
                                            className="btn-round"
                                            color="info"
                                            href="#pablo"
                                            onClick={this.handleSubmit}
                                            size="lg"
                                        >
                                            Send Message
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </>
        )
    }

    handleSubmit = async e => {
        ReactGA.event({
            category: 'User',
            action: 'Send email'
        });

        e.preventDefault();
        const response = await fetch('/api/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                post: {
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.email,
                    message: this.state.message,
                    token: this.state.token
                }
            }),
        });

        const body = await response.text();

        console.log(body);
    };
}

const LandingPageComponent = withGoogleReCaptcha(LandingPage);

export default LandingPageComponent