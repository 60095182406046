import React from 'react'

import { Container } from "reactstrap"

function Footer() {
    return (
        <>
            <footer className="footer footer-default">
                <Container>
                    <div className="copyright" id="copyright">
                        © {new Date().getFullYear()}, Designed by{" "}
                        <a
                            href="https://www.cloudbrics.com"
                            target="_blank"
                        >CloudBrics.</a> Coded by{" "}
                        <a
                            href="https://www.cloudbrics.com"
                            target="_blank"
                        >SVACY</a>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Footer