import React from 'react';

import { Container } from "reactstrap"

function Header() {
    return (
        <div id="home" className="page-header page-header-small">
            <div
                className="page-header-image"
                style={{
                    backgroundImage: "url(" + require("../assets/images/bg22.jpg") + ")"
                }}
            ></div>
            <div className="main-title content-center content-top">
                <Container>
                    <h1 className="title-stylish">Cloudbrics</h1>
                    <h3 className="title-small footer">Let us help you modernize your appliations</h3>
                </Container>
            </div>
        </div >
    )
}

export default Header